import React, { useEffect } from "react"
import {graphql } from "gatsby"
import Img from "gatsby-image"
import { Container, Row, Col } from 'react-bootstrap'
import Layout from "../components/layout"
import SEO from "../components/seo"

import WooC from "../images/woocommerce.inline.svg"
import PrestaS from "../images/Prestashop.inline.svg"
import OpenC from "../images/Opencart.inline.svg"
import WebIcon from "../images/on_website_icon.inline.svg"
import MagentoI from "../images/Magento.inline.svg"
import EasyD from "../images/easydigitaldownloads.inline.svg"
import ArrowN from "../images/Arrow_next.inline.svg"

import ApiImage from "../images/api.inline.svg"
import ApiI from "../images/api_icon.inline.svg"
import AddP from "../images/Add_plugin_icon.inline.svg"

import Websiteill from "../images/website_ill.png.inline.svg"

import ReQuest from "../images/Request_plugin.inline.svg"

import BtnA from "../images/btn_a.inline.svg"


function OnWebsite({ data, pageContext: { lang, currentPath } }) {
  useEffect(() => {

  }, [])
  return (
  <Layout>
    <SEO title="Home" />
      <Container className="above on_website in-person-page">
        <Row>
          <Col>
        <div className="above-tag"><WebIcon/><span>On website</span></div>
        <h1>Looking to accept dagcoins with your <strong>online shop?</strong></h1>
        <p>There are two ways to add dagcoin as a payment option</p>
        </Col>
        <Col className="images-above">
        <div className="aboveone"><Websiteill /></div>
        </Col>
        </Row>
      </Container> 
<Container className="plugins">
    <Row><h3>Plugins</h3></Row>
    <Row className="pluginsimg"><AddP/><p>Add an easily integratable plugin to your online shop or service. Dagpay plugins are available for the following popular eCommerce platforms:</p></Row>
    <div className="scroll-y">
    <Row className="pluginslist">
        
        <Col>
        <a href="https://help.dagpay.io/en/articles/4248471-woocommerce-plugin-integration-guide">
            <div className="pluginimage">
            <WooC/>
            </div>
            <p>Learn more <ArrowN/></p>
        </a>
        </Col>
        <Col>
        <a href="https://help.dagpay.io/en/articles/4248475-magento-2-extension-integration-guide">
            <div className="pluginimage">
            <MagentoI/>
            </div>
            <p>Learn more <ArrowN/></p>
        </a>
        </Col>
        <Col>
        <a href="https://help.dagpay.io/en/articles/4248502-opencart-extension-integration-guide">
            <div className="pluginimage">
            <OpenC/>
            </div>
            <p>Learn more <ArrowN/></p>
        </a>
        </Col>
        <Col>
        <a href="https://help.dagpay.io/en/articles/4248504-prestashop-module-integration-guide">
            <div className="pluginimage">
            <PrestaS/>
            </div>
            <p>Learn more <ArrowN/></p>
        </a>
        </Col>
        <Col>
        <a href="https://help.dagpay.io/en/articles/4248472-easy-digital-downloads-plugin-integration-guide">
            <div className="pluginimage">
            <EasyD/>
            </div>
            <p>Learn more <ArrowN/></p>
        </a>
        </Col>
        <Col className="requestplugin">
        <a href="mailto:info@dagpay.io">
            <div className="pluginimage">
            <ReQuest/>
            </div>
            <p>Request plugin</p>
        </a>
        </Col>
       
    </Row>
    </div>
    </Container> 

    <Container className="apiinte">
<Row>
    <Col>
    <h3>API integration</h3>
    <div className="api-content"><ApiI/><p>Follow a documented API which is available for any custom integration you need. Implementation requires at least some level of technicality or developer support.</p>
    <p>We can consult and help you in case you have any issues integrating the API.</p></div>
    <a target="_blank" href="https://app.dagpay.io/documentation" class="gray">API documentation<BtnA/></a>
    </Col>
    <Col>
        <ApiImage/>
    </Col>
</Row>
    </Container>
  </Layout>
  )
}
export const query = graphql`
  query {
    websiteill: file(relativePath: { eq: "website_ill.png" }) {
      childImageSharp {
        fluid(maxWidth: 9000, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
export default OnWebsite
